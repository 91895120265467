function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h4',[_vm._v("1.安装consul做服务发现  brew install consul")]),_vm._v(" "),_c('h4',[_vm._v("2.安装go-micro cli 脚手架  go install github.com/go-micro/cli/cmd/go-micro@latest")]),_vm._v(" "),_c('h4',[_vm._v("3.通过脚手架命令创建服务  go-micro new service helloworld")]),_vm._v(" "),_c('h4',[_vm._v("4.测试微服务  go-micro call helloworld Helloworld.Call '{\"name\": \"John\"}'")]),_vm._v(" "),_c('h4',[_vm._v("5.通过脚手架命令创建客户端  go-micro new client helloworld")]),_vm._v(" "),_c('h4',[_vm._v("6.go-micro微服务框架的consul插件  服务需导入 github.com/go-micro/plugins/v4/registry/consul")]),_vm._v(" "),_c('h4',[_vm._v("7.将微服务注册到服务发现中")]),_vm._v(" "),_c('h4',[_vm._v("8.客服端")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }